<template>
  <div>
    <v-chip
      v-if="abierta"
      :color="OPERACIONCRM.colorEstado.abierta"
      dark
      label
      small
      class="ml-1 mt-1"
    >
      Abierta
    </v-chip>
    <v-chip
      v-else
      :color="OPERACIONCRM.colorEstado.cerrada"
      dark
      label
      small
      class="ml-1 mt-1"
    >
      Cerrada
    </v-chip>
  </div>
</template>

<script>
import { OPERACIONCRM } from '@/utils/consts'

export default {
  props: {
    abierta:{
      type: Boolean,
    },
  },
  data () {
    return {
      OPERACIONCRM,
    }
  },
}
</script>
